import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAJPbbe92RqlOs067-Cp8AE1PUUTwKTgcM",
  authDomain: "video-reuser.firebaseapp.com",
  databaseURL: "https://video-reuser-default-rtdb.firebaseio.com",
  projectId: "video-reuser",
  storageBucket: "video-reuser.appspot.com",
  messagingSenderId: "598770590314",
  appId: "1:598770590314:web:fa41dac04d55f1e8682013",
  measurementId: "G-5B8SCKPSVV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Auth
const auth = getAuth(app);

// Export for use in other parts of your app
export { db, auth};
