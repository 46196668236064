// Import necessary React hooks and Firebase authentication functions
import React, { useContext, useState, useEffect } from 'react';
import { auth } from './firebase-config';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged
} from "firebase/auth";

// Create a context for authentication to be used throughout the app
const AuthContext = React.createContext();

// Custom hook to allow easy use of the context in other components
export function useAuth() {
  return useContext(AuthContext);
}

// The provider component that will wrap the part of the app where auth is used
export function AuthProvider({ children }) {
  // State to hold the current user, loading status, and any authentication errors
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to sign up a new user with email and password
  async function signup(email, password) {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      setCurrentUser(userCredential.user); // Update the current user state
      return userCredential; // Return for further use, e.g., redirecting the user
    } catch (err) {
      setError(err.message); // Set error state to display the error message in UI
      throw err; // Rethrow to allow handling it in the component where signup is called
    }
  }

  // Function to log in a user with email and password
  async function login(email, password) {
    try {
      setError(null); // Clear previous errors
      setLoading(true); // Indicate loading state during the login process
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setCurrentUser(userCredential.user); // Update the current user state
    } catch (err) {
      setError(err.message); // Update the error state with the new error
    } finally {
      setLoading(false); // Reset loading state regardless of login success or failure
    }
  }

  // Function to log out the current user
  async function logout() {
    try {
      setError(null); // Clear previous errors
      setLoading(true); // Indicate loading state during the logout process
      await signOut(auth);
      setCurrentUser(null); // Reset the current user state
    } catch (err) {
      setError(err.message); // Update the error state with the new error
    } finally {
      setLoading(false); // Reset loading state regardless of logout success or failure
    }
  }

  // Effect hook to subscribe to authentication state changes on component mount
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user); // Update current user state when auth state changes
      setLoading(false); // Reset loading state once the user is received
    });

    // Return a cleanup function to unsubscribe from the auth state listener on unmount
    return unsubscribe;
  }, []);

  // The value object contains everything that should be accessible via the context
  const value = {
    currentUser,
    signup,
    login,
    logout,
    loading,
    error // Make sure to include error in the context value for use in consumer components
  };

  // Render the provider with the value object, wrapping the children only when not loading
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
  
}