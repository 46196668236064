import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Adjust the path if necessary
import './Navigation.css'; // Ensure this path is correct for the CSS file

const Navigation = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  return (
    <nav className="side-nav">
      <ul>
        <li><Link to="/profile">Profile</Link></li>
        <li><Link to="/analytics">Analytics</Link></li>
        <li><Link to="/queue">Queue</Link></li>
        <li><Link to="/history">Post History</Link></li>
        <li><button onClick={handleLogout}>Log Out</button></li>
        {/* You can add more nav items here */}
      </ul>
    </nav>
  );
};

export default Navigation;

