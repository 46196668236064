import React, { useState, useEffect } from 'react';
import { db } from './firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import './Analytics.css';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';

const Analytics = () => {
  const [connectedAccounts, setConnectedAccounts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccounts = async () => {
      if (!currentUser) {
        setError('User is not authenticated');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const userDocRef = doc(db, 'Users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists() && userDocSnap.data().ConnectedAccounts) {
          setConnectedAccounts(userDocSnap.data().ConnectedAccounts);
        } else {
          setConnectedAccounts(null);
        }
      } catch (error) {
        console.error("Failed to fetch connected accounts", error);
        setError('Failed to fetch connected accounts');
      } finally {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, [currentUser]);

  const navigateToProfile = () => {
    navigate('/profile');
  };

  if (loading) {
    return <div className="centeredContent">Loading...</div>;
  }

  if (error) {
    return <div className="centeredContent">{error}</div>;
  }

  if (!connectedAccounts) {
    return (
      <div className="centeredContent">
        <p>No social media accounts connected.</p>
        <p>Please connect your social media accounts to view analytics.</p>
        <button onClick={navigateToProfile} className="connectButton">
          Connect Social Media Accounts
        </button>
      </div>
    );
  }

  return (
    <div className="analyticsDashboard">
      <h2>Social Media Analytics</h2>
      {Object.entries(connectedAccounts).map(([platform, account]) => (
        <div key={platform} className="accountAnalytics">
          <h3>{platform}</h3>
          <p>Access Token: {account.AccessToken}</p>
          <p>User ID: {account.UserID}</p>
        </div>
      ))}
    </div>
  );
};

export default Analytics;




