import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase-config';
import { useAuth } from './AuthContext';
import './Profile.css';

const Profile = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) {
      setError('No user is logged in');
      setLoading(false);
      return;
    }

    const fetchUserProfile = async () => {
      try {
        const userDocRef = doc(db, 'Users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserProfile(userDocSnap.data());
        } else {
          setError('User profile not found.');
        }
      } catch (error) {
        setError('An error occurred while fetching user profile');
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [currentUser]);

  const renderAccountCard = (platform) => {
    const isConnected = userProfile?.connectedAccounts?.[platform];
    const buttonClass = isConnected ? 'disconnect' : 'connect';
    const actionText = isConnected ? 'Disconnect' : 'Connect';

    return (
      <div key={platform} className="account-item">
        <span>{platform.charAt(0).toUpperCase() + platform.slice(1)}</span>
        <span className="account-status">{isConnected ? 'Connected' : 'Not connected'}</span>
        <button
          className={buttonClass}
          // Placeholder for actual connection logic
          onClick={() => console.log(`Connect/Disconnect ${platform}`)}
        >
          {actionText}
        </button>
      </div>
    );
  };

  if (loading) {
    return <div>Loading profile...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const platforms = ['facebook', 'instagram', 'x', 'linkedin', 'tiktok', 'youtube'];

  return (
    <div className="profile-container">
      <h2>User Profile</h2>
      <div className="connected-accounts">
        <h3>Connected Accounts</h3>
        {platforms.map(platform =>
          renderAccountCard(platform, userProfile?.connectedAccounts?.[platform])
        )}
      </div>
    </div>
  );
};

export default Profile;
