// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Profile from './Profile';
import Queue from './Queue';
import History from './History';
import Analytics from './Analytics';
import Signup from './Signup';
import Login from './Login';
import Navigation from './Navigation';
import './App.css';

function App() {
  const { currentUser } = useAuth();

  return (
    <Router>
      <div className="App">
        {currentUser && <Navigation />}
        <div className={currentUser ? "contentWithNav" : "centeredContent"}>
          <Routes>
            <Route path="/" element={currentUser ? <Navigate to="/analytics" /> : <Navigate to="/signup" />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            {/* Updated Route elements to use conditional rendering for protected routes */}
            <Route path="/profile" element={currentUser ? <Profile /> : <Navigate to="/login" />} />
            <Route path="/analytics" element={currentUser ? <Analytics /> : <Navigate to="/login" />} />
            <Route path="/queue" element={currentUser ? <Queue /> : <Navigate to="/login" />} />
            <Route path="/history" element={currentUser ? <History /> : <Navigate to="/login" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
